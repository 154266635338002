var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "centerx" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "modal-docs",
          attrs: {
            title:
              _vm.modalData.campaignId && _vm.modalData.companyId
                ? `${_vm.title} | Campaña: ${_vm.modalData.campaignId} - Empresa: ${_vm.modalData.companyId}`
                : _vm.title,
            active: _vm.poModalActive,
            id: "show-docs-popup",
            fullscreen: "",
          },
          on: {
            "update:active": function ($event) {
              _vm.poModalActive = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("files-table", {
            attrs: {
              purchaseOrders: _vm.modalData.purchaseOrders,
              variant: _vm.variant,
              currencies: _vm.currencies,
            },
            on: {
              "update:purchaseOrders": function ($event) {
                return _vm.$set(_vm.modalData, "purchaseOrders", $event)
              },
              "update:purchase-orders": function ($event) {
                return _vm.$set(_vm.modalData, "purchaseOrders", $event)
              },
              "update:currencies": function ($event) {
                _vm.currencies = $event
              },
              successFileUpdate: _vm.successFileUpdate,
            },
          }),
          _c("upload-docs", {
            ref: "uploadDocs",
            attrs: {
              campaignId: _vm.modalData.campaignId,
              companyId: _vm.modalData.companyId,
              currencies: _vm.currencies,
            },
            on: {
              "update:currencies": function ($event) {
                _vm.currencies = $event
              },
              successFileUpload: _vm.successFileUpload,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }