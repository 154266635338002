var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative w-full mt-4", attrs: { id: "upload-docs" } },
    [
      _c("section", { staticClass: "flex flex-col gap-4" }, [
        _c(
          "div",
          { staticClass: "flex justify-between items-center" },
          [
            _c("h2", { staticClass: "text-lg font-bold" }, [
              _vm._v("Odcs pendientes de subida"),
            ]),
            _c(
              "vs-button",
              {
                attrs: {
                  title: "Subir todas las ODCs pendientes",
                  disabled: _vm.pendingFiles.length === 0,
                  icon: "arrow_upward",
                  color: "primary",
                },
                on: { click: _vm.uploadAllFiles },
              },
              [_vm._v("Subir todas")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-2 w-full" },
          [
            _vm.pendingFiles.length === 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "bg-grey w-full p-4 rounded-lg flex gap-2 items-center justify-start",
                  },
                  [
                    _c("vs-icon", { attrs: { icon: "file_upload" } }),
                    _c("span", [
                      _vm._v("No hay archivos pendientes de subidas"),
                    ]),
                  ],
                  1
                )
              : _vm._l(_vm.pendingFiles, function (file) {
                  return _c(
                    "div",
                    {
                      key: file.id,
                      staticClass: "flex flex-col gap-2 overflow-hidden",
                      staticStyle: { "max-width": "100%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-between items-end w-full gap-1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex gap-2 flex-1 flex-wrap" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col justify-end gap-1",
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "text-xs text-gray-50 ml-1",
                                    },
                                    [_vm._v("Archivo")]
                                  ),
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass: "file-chip",
                                      attrs: { color: "primary" },
                                    },
                                    [
                                      _c("vs-avatar", {
                                        attrs: { icon: "file_present" },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(file.file.name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "vs-select",
                                {
                                  staticClass: "flex-1",
                                  attrs: {
                                    label: "Mes",
                                    placeholder: "Selecciona el mes",
                                  },
                                  model: {
                                    value: file.month,
                                    callback: function ($$v) {
                                      _vm.$set(file, "month", $$v)
                                    },
                                    expression: "file.month",
                                  },
                                },
                                [
                                  _vm._l(_vm.months, function (month) {
                                    return _c("vs-select-item", {
                                      key: month.value,
                                      attrs: {
                                        value: month.value,
                                        text: month.label,
                                      },
                                    })
                                  }),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v("Nada que mostrar.")]
                                  ),
                                ],
                                2
                              ),
                              _c("vs-input", {
                                staticClass: "flex-1",
                                attrs: {
                                  label: "Año",
                                  type: "number",
                                  placeholder: new Date().getFullYear(),
                                },
                                model: {
                                  value: file.year,
                                  callback: function ($$v) {
                                    _vm.$set(file, "year", $$v)
                                  },
                                  expression: "file.year",
                                },
                              }),
                              _c(
                                "vs-select",
                                {
                                  staticClass: "flex-1",
                                  attrs: {
                                    label: "Moneda",
                                    placeholder: "Selecciona la moneda",
                                  },
                                  model: {
                                    value: file.currency,
                                    callback: function ($$v) {
                                      _vm.$set(file, "currency", $$v)
                                    },
                                    expression: "file.currency",
                                  },
                                },
                                [
                                  _vm._l(_vm.currencies, function (currency) {
                                    return _c("vs-select-item", {
                                      key: currency.value,
                                      attrs: {
                                        value: currency.value,
                                        text: currency.text,
                                      },
                                    })
                                  }),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v("Nada que mostrar.")]
                                  ),
                                ],
                                2
                              ),
                              _c("vs-input", {
                                staticClass: "flex-1",
                                attrs: {
                                  label: "Monto total",
                                  type: "number",
                                  placeholder: "1000",
                                },
                                model: {
                                  value: file.total,
                                  callback: function ($$v) {
                                    _vm.$set(file, "total", $$v)
                                  },
                                  expression: "file.total",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex gap-2 items-end" },
                            [
                              _c("vs-button", {
                                attrs: {
                                  icon: "delete",
                                  color: "danger",
                                  type: "border",
                                  title: "Eliminar archivo",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile(file.id)
                                  },
                                },
                              }),
                              _c("vs-button", {
                                attrs: { icon: "cloud_upload", title: "Subir" },
                                on: {
                                  click: function ($event) {
                                    return _vm.uploadFile(file.id)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "drop-zone mb-4 border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-primary transition-colors",
          on: {
            dragover: function ($event) {
              $event.preventDefault()
            },
            dragleave: function ($event) {
              $event.preventDefault()
            },
            drop: function ($event) {
              $event.preventDefault()
              return _vm.handleDrop.apply(null, arguments)
            },
            click: _vm.openFileInput,
          },
        },
        [
          _c("input", {
            staticClass: "hidden",
            attrs: {
              type: "file",
              name: `upload-file-input-${_vm.campaignId}-${_vm.companyId}`,
              id: `upload-file-input-${_vm.campaignId}-${_vm.companyId}`,
              multiple: "",
              accept: "application/pdf",
            },
            on: { change: _vm.handleFileUpload },
          }),
          _vm._m(0),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex flex-col items-center justify-center" },
      [
        _c("i", { staticClass: "material-icons text-4xl text-gray-400 mb-2" }, [
          _vm._v("cloud_upload"),
        ]),
        _c("p", { staticClass: "text-gray-500" }, [
          _vm._v("Arrastrá y soltá los archivos acá"),
        ]),
        _c("p", { staticClass: "text-xs text-gray-400" }, [
          _vm._v("o hacé click para seleccionar"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }