<template>
    <div class="centerx">
      <vs-popup 
        :title="modalData.campaignId && modalData.companyId ? `${title} | Campaña: ${modalData.campaignId} - Empresa: ${modalData.companyId}` : title"
        :active.sync="poModalActive"
        @close="handleClose"
        class="modal-docs"
        id="show-docs-popup"
        fullscreen
      >
        <!-- <h4 class="mb-4 text-lg text-bold text-black-light">Activos</h4> -->
        <files-table 
          :purchaseOrders.sync="modalData.purchaseOrders" 
          :variant="variant" 
          :currencies.sync="currencies"
          @successFileUpdate="successFileUpdate"
        />
        <upload-docs 
          :campaignId="modalData.campaignId"
          :companyId="modalData.companyId"
          :currencies.sync="currencies"
          @successFileUpload="successFileUpload"
          ref="uploadDocs"
        />
      </vs-popup>
    </div>
</template>

<style>

  
</style>

<script>
import FilesTable from './FilesTable.vue';
import UploadDocs from './upload-docs/UploadDocs.vue';

export default {
    name: "show-docs-popup",
    components: {
        FilesTable,
        UploadDocs
    },
    props: {
        poModalActive: {
            type: Boolean,
            default: false
        },
        poModalData: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        variant: {
          type: String,
          default: 'billing'
        },
        currencies: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            modalData: {campaignId: null, companyId: null, purchaseOrders: []},
        }
    },
    watch: {
        poModalData(newVal) {
            this.modalData = newVal;
        },
        localData: {
          handler(newVal) {
            // Send data to api and update table data
            console.log(newVal);
          },
          deep: true
        }
    },
    methods: {
        handleClose() {
            this.addOdcData = {
              file: null,
              month: null,
              year: null,
              total: null
            }
            this.$refs.uploadDocs.clearFiles();
            this.$emit('update:poModalActive', false); // Notifica al componente padre
        },
        openFileInput() {
            document.getElementById(`addOdcInput-${this.modalData.campaignId}-${this.modalData.companyId}`).click();
        },
        handleFileUpload(event) {
          const file = event.target.files[0];
          if (!file) return;
          this.addOdcData.file = file;
        },
        successFileUpload(response) {
          const {campaignId, companyId} = this.modalData;
          // Notify user
          this.$vs.notify({
            title: 'Archivo subido correctamente',
            text: `Se ha subido el archivo correctamente :)`,
            color: 'success'
          });

          const {data} = response.data;

          // Add new file to local data
          this.modalData.purchaseOrders.push({
            id: data.id,
            file: data.file,
            created_at: data.created_at,
            year: data.year,
            month: data.month,
            total_amount: data.total_amount
          });

          this.updateRowOdcData(campaignId, companyId, this.modalData.purchaseOrders);
        },
        successFileUpdate(response) {
          const {campaignId, companyId} = this.modalData;
          // Notify user
          this.$vs.notify({
            title: 'Archivo actualizado correctamente',
            text: `Se actualizó correctamente la ODC`,
            color: 'success'
          });

          const {data} = response.data;


          // Update local data
          const newData = this.modalData.purchaseOrders.map(purchaseOrder => {
            if(purchaseOrder.id === data.id) {
              return {
                id: data.id,
                file: data.file,
                created_at: data.created_at,
                year: data.year,
                month: data.month,
                currency: data.currency,
                total_amount: data.total_amount,
                invoiced_at: data.invoiced_at,
                paid_at: data.paid_at,
                canceled_at: data.canceled_at
              }
            }
            return purchaseOrder;
          });

          this.modalData.purchaseOrders = newData;

          this.updateRowOdcData({
            campaignId, 
            companyId, 
            newPurchaseOrders: newData
          });
        },
        updateRowOdcData({campaignId, companyId, newPurchaseOrders}) {
          this.$emit('updatePurchaseOrders', {campaignId, companyId, newPurchaseOrders});
        }
        // markAsNull(id) {
        //     const item = this.localData.find(item => item.id === id);
        //     const isNull = item.null;

        //     this.localData.find(item => item.id === id).null = !isNull;
        // }

    }
}


</script>