<template>
    <vs-select
        id="country-filter"
        class="w-full"
        :clearable="false"
        multiple
        @input="setCountries"
        v-model="countriesSelect"
    >
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in countryOptions" />
        <span slot="no-options">Nada que mostrar.</span>
    </vs-select>
</template>

  
  <script>
  import { sortAlphabetize } from '@/utils/utils.js'
  import Vue from 'vue'
    
  export default Vue.extend({
    data () {
        return {
            //default value
            countriesSelect: ['all'],
            countryOptions: this.getCountries()
        }
    },
    methods: {
        getCountries () {
            const userInfo = JSON.parse(localStorage.userInfo) 
            let countries = userInfo.authorized_countries.map((country) => ({
                ...country,
                value : country.name,
                text : country.name
            }))
            countries = sortAlphabetize(countries, 'text')
            countries.unshift({value : 'all', text : 'Todos'})
            // this.existOptionAll()
    
            return countries
        },
        setCountries () {
            this.existOptionAll()
            this.params.handleFilter({
                [this.params.filterName || 'countries']: this.countriesSelect.includes('all') ? null : this.countriesSelect,
            });
        },
      //If something other than "all" is selected, it's removed. If select "all" again, everything is deleted.
        existOptionAll () {
            const positionOfAll = this.countriesSelect.indexOf('all')

            if (positionOfAll === 0) this.countriesSelect.splice(positionOfAll, 1)
            if ((positionOfAll > 0) || (this.countriesSelect.length === 0)) this.countriesSelect = ['all']
        }
    },
    mounted() {
        const values = this.params.getValues()
        if (values[this.params.filterName || 'countries'] !== null && values[this.params.filterName || 'countries'] !== undefined) {
            this.countriesSelect = values[this.params.filterName || 'countries']
        }
    }
})
</script>