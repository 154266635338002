<template>
    <div id="upload-docs" class="relative w-full mt-4">
      <!-- Pending files area -->
      <section class="flex flex-col gap-4">
        <div class="flex justify-between items-center">
          <h2 class="text-lg font-bold">Odcs pendientes de subida</h2>
          <vs-button title="Subir todas las ODCs pendientes" :disabled="pendingFiles.length === 0" icon="arrow_upward" color="primary" @click="uploadAllFiles">Subir todas</vs-button>
        </div>
        <div class="mb-2 w-full">
          <div class="bg-grey w-full p-4 rounded-lg flex gap-2 items-center justify-start" v-if="pendingFiles.length === 0">
            <vs-icon icon="file_upload"></vs-icon>
            <span>No hay archivos pendientes de subidas</span>
          </div>
          <div v-else v-for="file in pendingFiles" :key="file.id" class="flex flex-col gap-2 overflow-hidden" style="max-width: 100%;">
            <div class="flex justify-between items-end w-full gap-1">
              <div class="flex gap-2 flex-1 flex-wrap">
                <div class="flex flex-col justify-end gap-1">
                  <p class="text-xs text-gray-50 ml-1">Archivo</p>
                  <vs-chip color="primary" class="file-chip">
                    <vs-avatar icon="file_present"></vs-avatar>
                    <span>{{ file.file.name }}</span>
                  </vs-chip>
                </div>
                <vs-select 
                  label="Mes"
                  v-model="file.month"
                  placeholder="Selecciona el mes"
                  class=" flex-1"
                >
                  <vs-select-item
                    v-for="month in months"
                    :key="month.value"
                    :value="month.value"
                    :text="month.label"
                  />
                  <span slot="no-options">Nada que mostrar.</span>
                </vs-select>
                <vs-input 
                  label="Año"
                  type="number"
                  :placeholder="new Date().getFullYear()"
                  v-model="file.year"
                  class=" flex-1"
                />
                <vs-select 
                  label="Moneda"
                  v-model="file.currency"
                  placeholder="Selecciona la moneda"
                  class=" flex-1"
                >
                  <vs-select-item
                    v-for="currency in currencies"
                    :key="currency.value"
                    :value="currency.value"
                    :text="currency.text"
                  />
                  <span slot="no-options">Nada que mostrar.</span>
                </vs-select>
                <vs-input 
                  label="Monto total"
                  type="number"
                  placeholder="1000"
                  v-model="file.total"
                  class=" flex-1"
                />
              </div>
              <div class="flex gap-2 items-end">
                  <vs-button icon="delete" color="danger" type="border" title="Eliminar archivo" @click="removeFile(file.id)"></vs-button>
                  <vs-button icon="cloud_upload" title="Subir" @click="uploadFile(file.id)"></vs-button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div 
        class="drop-zone mb-4 border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-primary transition-colors"
        @dragover.prevent
        @dragleave.prevent
        @drop.prevent="handleDrop"
        @click="openFileInput"
      >
        <input 
          type="file" 
          :name="`upload-file-input-${campaignId}-${companyId}`" 
          :id="`upload-file-input-${campaignId}-${companyId}`" 
          class="hidden"
          @change="handleFileUpload"
          multiple
          accept="application/pdf"
        >
        <div class="flex flex-col items-center justify-center">
          <i class="material-icons text-4xl text-gray-400 mb-2">cloud_upload</i>
          <p class="text-gray-500">Arrastrá y soltá los archivos acá</p>
          <p class="text-xs text-gray-400">o hacé click para seleccionar</p>
        </div>
      </div>
    </div>
</template>

<script>
import { monthFormatter } from '../../../../utils/strings';
import { sortAlphabetize } from '../../../../utils/utils';
import appConfig from '@/../appConfig.js';

export default {
    name: 'upload-docs',
    props: {
        campaignId: {
            type: Number
        },
        companyId: {
            type: Number
        },
        currencies: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            monthFormatter,
            pendingFiles: [],
            loading: false,
            months: [
              {value: 0, label: 'Provisoria'},
              {value: 1, label: 'Enero'},
              {value: 2, label: 'Febrero'},
              {value: 3, label: 'Marzo'},
              {value: 4, label: 'Abril'},
              {value: 5, label: 'Mayo'},
              {value: 6, label: 'Junio'},
              {value: 7, label: 'Julio'},
              {value: 8, label: 'Agosto'},
              {value: 9, label: 'Septiembre'},
              {value: 10, label: 'Octubre'},
              {value: 11, label: 'Noviembre'},
              {value: 12, label: 'Diciembre'},
            ]
        }
    },
    methods: {
        insertFile(file) {
            this.pendingFiles.push({
              id: this.pendingFiles.length + 1,
              file,
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
              total: null,
              currency: 'ARS',
              is_invoiced: 0,
              is_canceled: 0,
              is_paid: 0,
            });
        },
        clearFiles() {
          this.pendingFiles = [];
        },
        removeFile(id) {
            this.pendingFiles = this.pendingFiles.filter(file => file.id !== id);
        },
        uploadFile(id) {
          const odc = this.pendingFiles.find(file => file.id === id);
          if (!odc) return;

          if(!odc.file || odc.month === null || !odc.year || !odc.total) {
                this.$vs.notify({
                    title: 'Error',
                    text: 'Por favor complete todos los campos',
                    color: 'danger'
                });
                return;
          }

          if(!this.campaignId || !this.companyId) {
              this.$vs.notify({
                  title: 'Error',
                  text: 'No hay campaña o compañía seleccionada',
                  color: 'danger'
              });
              return;
          }
          // Show loading notification
          this.loading = true;


          const formData = new FormData();
          formData.append('file', odc.file);
          formData.append('year', odc.year);
          formData.append('month', odc.month);
          formData.append('total', odc.total);
          formData.append('currency', odc.currency);
          formData.append('is_invoiced', odc.is_invoiced);
          formData.append('is_canceled', odc.is_canceled);
          formData.append('is_paid', odc.is_paid);

          this.$http.post(`${appConfig.apiUrl}/campaigns/${this.campaignId}/companies/${this.companyId}/purchase-orders/upload`, formData)
          .then((response) => {
              this.removeFile(id);
              this.$emit('successFileUpload', response);
          })
          .catch((error) => {
              console.error(error);
              this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  color: 'danger'
              });
          })
          .finally(() => {
              this.loading = false;
          });
        },
        uploadAllFiles() {
          this.pendingFiles.forEach(file => {
            this.uploadFile(file.id);
          });
        },
        openFileInput() {
            document.getElementById(`upload-file-input-${this.campaignId}-${this.companyId}`).click();
        },
        handleFileUpload(event) {
            const files = Array.from(event.target.files || []);
            if (files.length === 0) return;
            files.forEach(file => {
                if (file.type !== 'application/pdf') {
                    return this.$vs.notify({
                        title: file.name,
                        text: 'Solo se permiten archivos PDF',
                        color: 'danger'
                    });
                }

                this.insertFile(file);
            });
        },
        handleDrop(event) {
          const files = Array.from(event.dataTransfer.files || []);
          if (files.length === 0) return;

          files.forEach(file => {
            if (file.type !== 'application/pdf') {
              this.$vs.notify({
                title: file.name,
                text: 'Solo se permiten archivos PDF',
                color: 'danger'
              });
              return;
            }

            this.insertFile(file);
          });
        },
        async getCurrencies () {
            this.loading = true
            try {
              const response = await this.$http.get(`${appConfig.apiUrl}/countries`)
              let currencies = response.data.map((country) => ({
                label: country.default_currency.code,
                value: country.default_currency.code
              }))
              currencies = [...new Set(currencies.map(c => JSON.stringify(c)))].map(str => JSON.parse(str))
              currencies = sortAlphabetize(currencies, 'label')

              return currencies
            } catch (error) {
              console.log(error)
            } finally {
              this.loading = false
            }
        },
        openLoading() {
          this.$vs.loading({
            text: 'Subiendo archivo...',
          });
        },
        closeLoading() {
          this.$vs.loading.close();
        }
    },
    watch: {
      loading(newVal) {
        if(newVal) {
          this.openLoading();
        } else {
          this.closeLoading();
        }
      }
    }
}
</script>

<style scoped>
.drop-zone {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-zone:hover {
    border-color: var(--vs-primary) !important;
}

.drop-zone:hover i,
.drop-zone:hover p {
    color: var(--vs-primary);
}

.drop-zone i,
.drop-zone p {
    transition: color 0.3s ease;
}

.file-chip .text-chip span{
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style> 